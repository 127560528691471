import { endpointAdapterFactory, schemaMapperFactory } from '@empathyco/x-adapter';
import { getProxyServiceURL } from '../mappers/schemas/results';
import { getEncodedSignature } from '../../utils/encryption';
import { getEndpointRoute } from '../../utils/environment';
import { MyMotiveStore } from '../models/entitites/result';

/**
 * Returns the store info endpoint URL based on the current environment.
 *
 * Depending on the host of the current document location, it will concatenate
 * the appropriate environment variable with the store counter path.
 *
 * @returns The complete store counter endpoint URL.
 */
function getStoreInfoEndpoint(): string {
  return `${getEndpointRoute()}search/v1/query/mymotivemarketplace/stores`;
}

export const storeInfo = endpointAdapterFactory<any, any>({
  endpoint: getStoreInfoEndpoint,
  responseMapper: schemaMapperFactory<any, any>({
    storeInfo: response => storeInfoMapper(response.stores.content as MyMotiveStore[])
  }),
  defaultRequestOptions: {
    parameters: {
      start: '0',
      rows: '300'
    }
  }
});

/**
 * Maps result images by prepending the proxy service URL to each one.
 *
 * @param image - The images to map.
 * @returns A mapped images.
 */
function mapImage(image: string): string {
  if (image.includes('imgproxy')) {
    return image;
  }
  const proxyURL = getProxyServiceURL();
  return proxyURL + getEncodedSignature(image);
}

interface StaticImages {
  [key: string]: string;
}

export const staticLogos: StaticImages = {
  '250 Gramos de Queso': '250_gramos_de_queso.png',
  'Agroalimentaria del Oriente SL': 'agroalimentaria_del_oriente_sl.png',
  'Alskin Cosmetics': 'alskin_cosmetics.png',
  aresso: 'aresso.png',
  arteago: 'arteago.png',
  Asvarta: 'asvarta.png',
  Bevilud: 'bevilud.png',
  Biciclasica: 'biciclasica.png',
  Bigoutlet: 'bigoutlet.png',
  Biodieta: 'biodieta.png',
  Bruyere: 'bruyere.png',
  Cafento: 'cafento.png',
  'Carlitos Baby': 'carlitos_baby.png',
  'Chupatintas S.L.': 'chupatintas_sl.png',
  'Coalla Gourmet': 'coalla_gourmet.png',
  'Coral Golf': 'coral_golf.png',
  coseno: 'coseno.png',
  'Covadonga Sports': 'covadonga_sports.png',
  'Culture Bike': 'culture_bike.png',
  'EMBUTIDOS LA UNION, S.L.': 'emubtidos_la_union_sl.png',
  Endorshop: 'endorshop.png',
  'Find Your Everest': 'find_your_everest.png',
  gam: 'gam.png',
  'Hoopoe Running': 'hoopoe_running.png',
  'IPG Dental': 'ipg_dental.png',
  'Joyeria Roibás': 'joyeria_roibas.png',
  'CAPUA HOBBY’S': 'capua_hobbys.png',
  'Letty B.M.': 'letty_bm.png',
  Parafernalia: 'parafernalia.png',
  Reveymo: 'reveymo.png',
  'Sherman Survival': 'sherman_survival.png',
  siroko: 'siroko.png',
  sporting: 'sporting.png',
  'Tablas Surf Shop': 'tablas_surf_shop.png',
  'Tejidos El Mundo': 'tejidos_el_mundo.png',
  'Tiendas Marvelous': 'tiendas_marvelous.png',
  'Universo Balonmano': 'universo_balonmano.png',
  ZonaIndoor: 'zonaindoor.png',
  Redink: 'redink.png',
  Kbike: 'kbike.png',
  Regalos007: 'regalos007.png',
  'Union Ferretera': 'unionferretera.png',
  'Quesos Asturianos': 'quesosasturianos.png',
  Silbetika: 'silbetika.png',
  'Master Ecu': 'master_ecu.jpg',
  'Mobel 6000': 'mobel_6000.svg',
  Deportestrainer: 'deportes_trainer.png',
  Frikiverso: 'frikiverso.png',
  'Simple Moda': 'simplemoda.png',
  'NKL Budo Shop': 'nkl_budo_shop.png',
  'Unblemish Ltd': 'unblemish.png'
};

export const staticBackground: StaticImages = {
  'CAPUA HOBBY’S': 'capua_hobbys.jpg',
  'Chupatintas S.L.': 'chupatintas.jpg',
  Bigoutlet: 'bigoutlet.jpg',
  'Union Ferretera': 'unionferretera.jpg',
  'Quesos Asturianos': 'quesosasturianos.jpg',
  GlobyShop: 'globyshop.jpg',
  'Colchonería Oporto': 'colchoneria_oporto.png'
};

/**
 * Maps the store info from the response.
 *
 * @param infoArray - The info array.
 * @returns The mapped store info.
 */
function storeInfoMapper(infoArray: MyMotiveStore[]): MyMotiveStore[] {
  infoArray.forEach((store: MyMotiveStore) => {
    if (store.logoUrl || staticLogos[store.name.toString()]) {
      if (staticLogos[store.name.toString()]) {
        store.logoUrl = `logos/${staticLogos[store.name.toString()]}`;
      } else {
        store.logoUrl = mapImage(store.logoUrl);
      }
    }
    if (store.photoUrl || staticBackground[store.name.toString()]) {
      if (staticBackground[store.name.toString()]) {
        store.photoUrl = `background/${staticBackground[store.name.toString()]}`;
      } else {
        store.photoUrl = mapImage(store.photoUrl);
      }
    }
  });
  return infoArray;
}
