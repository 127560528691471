import { SearchXStoreModule } from '@empathyco/x-components/search';
import { Result } from '@empathyco/x-types';
let previousStore: string | any = null;
let previousArea: string | any = null;

/**
 * Default implementation for the {@link SearchActions.saveSearchResponse}.
 *
 * @param context - The {@link https://vuex.vuejs.org/guide/actions.html | context} of the actions,
 * provided by Vuex.
 * @param response - The {@link @empathyco/x-types#SearchResponse} to save.
 *
 * @public
 */
export const saveSearchResponse: SearchXStoreModule['actions']['saveSearchResponse'] = (
  { commit, state, getters },
  {
    results,
    partialResults,
    facets,
    banners,
    promoteds,
    totalResults,
    spellcheck,
    redirections,
    queryTagging,
    displayTagging
  }
) => {
  const currentStore = getters.request?.extraParams?.store as string;
  const currentArea = getters.request?.extraParams?.area as string;

  if (currentStore !== previousStore || currentArea !== previousArea) {
    commit('setIsAppendResults', false);
    previousStore = currentStore;
    previousArea = currentArea;
  }
  if (totalResults === 0) {
    commit('setIsNoResults', true);
    if (getters.request && Object.keys(getters.request.filters!).length > 0) {
      commit('setFromNoResultsWithFilters', true);
    }
  } else {
    commit('setIsNoResults', false);
  }

  const addAreatoTagging = (result: Result): Result => {
    return result.tagging?.click
      ? {
          ...result,
          tagging: {
            ...result.tagging,
            click: {
              ...result.tagging.click,
              params: {
                area: currentArea, // if the area is present in the params, don't override it
                ...result.tagging.click.params
              }
            }
          }
        }
      : result;
  };

  const resultsToSave = results.map(result => addAreatoTagging(result));

  if (state.isAppendResults) {
    commit('appendResults', resultsToSave);
  } else {
    commit('setResults', resultsToSave);
    commit('setBanners', banners ?? []);
    commit('setPromoteds', promoteds ?? []);
    commit('setRedirections', redirections ?? []);
  }

  const partialResultsToSave = (partialResults ?? []).map(partialResult => {
    return {
      ...partialResult,
      results: (partialResult.results ?? []).map(result => addAreatoTagging(result))
    };
  });

  commit('setPartialResults', partialResultsToSave);

  if (facets) {
    commit('setFacets', facets);
  }

  if (queryTagging) {
    commit('setQueryTagging', {
      ...queryTagging,
      params: {
        area: currentArea, // if the area is present in the params, don't override it
        ...queryTagging?.params
      }
    });
  }

  if (displayTagging) {
    commit('setDisplayTagging', {
      ...displayTagging,
      params: {
        area: currentArea, // if the area is present in the params, don't override it
        ...displayTagging?.params
      }
    });
  }

  commit('setTotalResults', totalResults);
  commit('setSpellcheck', spellcheck ?? '');
};
