import {
  PlatformAdapter,
  platformAdapter,
  PlatformResult,
  resultSchema
} from '@empathyco/x-adapter-platform';
import { Result } from '@empathyco/x-types';
import {
  bannerSchemaOverride,
  promotedSchemaOverride,
  resultSchemaOverride
} from './mappers/schemas/results';
import { recommendationsRequestSchemaOverride } from './mappers/schemas/recommendations';
import { semanticQueriesRequestSchemaOverride } from './mappers/schemas/semantic-queries';
import { facetSchemaOverride } from './mappers/schemas/facets';
import { relatedPrompts } from './endpoints/relatedPrompts';
import { storeResults } from './endpoints/store-results';
import { storeInfo } from './endpoints/store-info';
import { storePreview } from './endpoints/store-preview';

/**
 * Initializing all schema overrides.
 *
 * @remarks This IIFE function is being executed immediately.
 */
(function initSchemaOverrides(): void {
  resultSchemaOverride();
  recommendationsRequestSchemaOverride();
  semanticQueriesRequestSchemaOverride();
  facetSchemaOverride();
  bannerSchemaOverride();
  promotedSchemaOverride();
})();

export const adapter: PlatformAdapter = {
  ...platformAdapter,
  relatedPrompts: relatedPrompts,
  storeResults: storeResults,
  storeInfo: storeInfo,
  storePreview: storePreview
};

interface MyMotivePlatformResult extends PlatformResult {
  price?: {
    current: {
      value: number;
    };
    previous?: {
      value: number;
    };
    future?: {
      value: number;
    };
  };
}

resultSchema.$override<MyMotivePlatformResult, Partial<Result>>({
  price: ({ __prices: rawPrices }) => {
    if (rawPrices) {
      return {
        value: rawPrices.current.value,
        originalValue: rawPrices.previous?.value ?? rawPrices.current?.value,
        futureValue: rawPrices.future?.value ?? rawPrices.current?.value,
        hasDiscount:
          rawPrices.current.value < (rawPrices.previous?.value ?? rawPrices.current.value)
      };
    }
    return undefined;
  }
});
