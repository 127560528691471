import { CustomSearchXStoreModule } from '../types';
import { adapter } from '../../../adapter/adapter';

/**
 *
 * @returns A Promise of search response that resolves when it fetches search response.
 *
 * @public
 */
// eslint-disable-next-line max-len
export const fetchStorePreviewResponse: CustomSearchXStoreModule['actions']['fetchStorePreviewResponse'] =
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call

  (_context, store) => {
    return adapter.storePreview(store);
  };
