import {
  filter,
  namespacedWireCommit,
  namespacedWireDispatch,
  Wiring
} from '@empathyco/x-components';
import { SearchActions, SearchMutations, setSearchQuery } from '@empathyco/x-components/search';

const wireDispatch = namespacedWireDispatch('search');
const wireCommit = namespacedWireCommit('search');

export const setMapParameters: any = wireDispatch('setMapParameters' as keyof SearchActions);
export const fetchAndSaveStoreInfoResponseWire: any = wireDispatch(
  'fetchAndSaveStoreInfoResponse' as keyof SearchActions
);
export const fetchAndSaveStoreInfoResponseByStoreWire: any = wireDispatch(
  'fetchAndSaveStoreInfoResponseByStore' as keyof SearchActions
);
export const ClearSingleShopSearchParameters = wireDispatch(
  'clearSingleShopSearchBox' as keyof SearchActions
);
export const fetchAndSaveStoreResultsResponseWire: any = wireDispatch(
  'fetchAndSaveStoreResultsResponse' as keyof SearchActions
);

export const fetchAndSaveExtraResultsResponseWire: any = wireDispatch(
  'fetchAndSaveExtraResultsResponse' as keyof SearchActions
);

export const fetchAndSaveStorePreviewResponseWire: any = wireDispatch(
  'fetchAndSaveStorePreviewResponse' as keyof SearchActions
);

export const resetStoreResults = wireCommit('resetStoreResults' as keyof SearchMutations);
export const resetExtraResults = wireCommit('resetExtraResults' as keyof SearchMutations);
export const setSearchSingleShopQuery = wireCommit('setSingleShopQuery' as keyof SearchMutations);
export const setSearchSingleShopName = wireCommit('setSingleShopName' as keyof SearchMutations);
export const resetSingleShopState = wireCommit('resetSingleShopState' as keyof SearchMutations);

export const resetAppending = wireCommit('setIsAppendStoreResults' as keyof SearchMutations, false);

export const increasePageAppendingCarouselsWire = wireDispatch(
  'increasePageAppendingCarousels' as keyof SearchActions
);

const setSearchQueryFiltered = filter(
  setSearchQuery,
  ({ eventPayload }) => !eventPayload.startsWith('::')
);

export const customSearchWiring: Partial<Wiring> = {
  UserAcceptedASingleShopQuery: { setSearchSingleShopQuery },
  UserClickedASingleShop: { setSearchSingleShopName },
  ClearSingleShopSearchBox: { ClearSingleShopSearchParameters },
  resetSingleShopState: { resetSingleShopState },
  SearchRequestUpdated: {
    fetchAndSaveStoreResultsResponseWire
  },
  InitializeWithoutCity: {
    fetchAndSaveStoreInfoResponseWire
  },
  StoreResultsChanged: {
    resetAppending
  },
  UserClearedQuery: {
    resetStoreResults,
    resetExtraResults
  },
  UserPickedParams: {
    fetchAndSaveStoreInfoResponseWire
  },
  //GetStorePreview: {
  //  fetchAndSaveStorePreviewResponseWire
  //},
  ShouldLoadExtraResults: {
    fetchAndSaveExtraResultsResponseWire
  },
  UserAcceptedAQuery: {
    setSearchQuery: setSearchQueryFiltered
  },
  UserReachedEndOfResultsPreview: {
    increasePageAppendingCarouselsWire
  }
};
