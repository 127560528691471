/**
 * Default implementation for the {@link QueriesPreviewActions.fetchAndSaveQueryPreview}.
 *
 * @param _context - The {@link https://vuex.vuejs.org/guide/actions.html | context} of the actions,
 * provided by Vuex.
 * @param request - The query preview request to make.
 * @returns A Promise of a SearchResponse when it fetches the results.
 *
 * @public
 */

import {
  getHashFromQueryPreviewItem,
  QueriesPreviewXStoreModule,
  QueryPreviewItem
} from '@empathyco/x-components/queries-preview';
import { Result } from '@empathyco/x-types';

const addAreatoTagging = (result: Result, currentArea: string): Result => {
  return result.tagging?.click
    ? {
        ...result,
        tagging: {
          ...result.tagging,
          click: {
            ...result.tagging.click,
            params: {
              area: currentArea, // if the area is present in the params, don't override it
              ...result.tagging.click.params
            }
          }
        }
      }
    : result;
};

// eslint-disable-next-line max-len
export const fetchAndSaveQueryPreview: QueriesPreviewXStoreModule['actions']['fetchAndSaveQueryPreview'] =
  ({ dispatch, commit }, request) => {
    const { query } = request;

    if (!query) {
      return;
    }

    const currentArea = request?.extraParams?.area as string;

    const queryPreviewItem: QueryPreviewItem = {
      request,
      results: [],
      status: 'loading',
      totalResults: 0,
      instances: 1
    };

    commit('setQueryPreviewCached', queryPreviewItem);

    return dispatch('fetchQueryPreview', request)
      .then(response => {
        const resultsToSave = response?.results
          ? response.results.map(result => addAreatoTagging(result, currentArea))
          : [];

        const displayTagging = response?.displayTagging
          ? {
              ...response?.displayTagging,
              params: {
                area: currentArea, // if the area is present in the params, don't override it
                ...response?.displayTagging.params
              }
            }
          : undefined;

        const queryTagging = response?.queryTagging
          ? {
              ...response?.queryTagging,
              params: {
                area: currentArea, // if the area is present in the params, don't override it
                ...response?.queryTagging.params
              }
            }
          : undefined;

        commit('setQueryPreviewCached', {
          request,
          results: resultsToSave,
          status: 'success',
          totalResults: response?.totalResults ?? 0,
          instances: 1,
          displayTagging,
          queryTagging
        });
      })
      .catch(() => {
        const queryPreviewHash = getHashFromQueryPreviewItem(queryPreviewItem);
        commit('setStatus', { queryPreviewHash, status: 'error' });
      });
  };
