/* eslint-disable max-len */
import { Dictionary } from '@empathyco/x-utils';

const seoTexts: Dictionary<{ title: string; description: string }> = {
  '/': {
    title: 'MotiveMarket.com - Encuentra productos sin salir de tu ciudad',
    description:
      // eslint-disable-next-line max-len
      'Busca en las tiendas de Madrid y Asturias y encuentra productos sin salir de tu ciudad, apoyando el comercio local.'
  },
  '/amagijon': {
    title: 'amagijon x MotiveMarket.com - Encuentra productos en Gijón',
    description:
      // eslint-disable-next-line max-len
      'Busca en las tiendas de Gijón y Asturias y encuentra productos sin salir de la ciudad, apoyando el comercio local. Olvídate de Amazon, amagijon.'
  },
  '/about-us': {
    title: 'MotiveMarket.com - Sobre nosotros',
    description:
      // eslint-disable-next-line max-len
      'Sobre nosotros - Busca en las tiendas de Madrid y Asturias y encuentra productos sin salir de tu ciudad'
  },
  '/legal': {
    title: 'MotiveMarket.com - Términos y condiciones',
    description:
      // eslint-disable-next-line max-len
      'Términos y Condiciones - Comercio local, libre de cookies'
  },
  '/join-us': {
    title: 'MotiveMarket.com - Únete',
    description:
      // eslint-disable-next-line max-len
      'Pon tu tienda en MotiveMarket.com. Aumenta el tráfico y mejora la visibilidad de tu tienda. Evita las tasas y comisiones de marketplaces como Amazon.'
  },
  '/privacy-policy': {
    title: 'MotiveMarket.com - Política de Privacidad',
    description:
      'Si quieres saber más sobre nuestra política de privacidad, este es el lugar adecuado.'
  }
};

export const useSeoComposable = (): {
  setSeo: ({ path, store, query }: { path: string; store?: string; query?: string }) => void;
} => {
  const setSeo = ({
    path,
    store,
    query
  }: {
    path: string;
    store?: string;
    query?: string;
  }): void => {
    let title = seoTexts[path]?.title;
    let description = seoTexts[path]?.description;
    const descriptionTag = document.querySelector('meta[name="description"]');
    const keywordsTag = document.querySelector('meta[name="keywords"]');

    if (path === '/') {
      if (store) {
        title = title?.replace('sin salir de tu ciudad', `en ${store}`);
        description = description?.replace('sin salir de tu ciudad', `en ${store}`);
      }

      if (query) {
        title = title?.replace('productos', query);
        description = description?.replace('productos', query);
      }
    }

    if (path === '/amagijon') {
      if (store) {
        title = title?.replace('en Gijón', `en ${store}`);
        description = description?.replace('en las tiendas de Gijón y Asturias', `en ${store}`);
      }

      if (query) {
        title = title?.replace('productos', query);
        description = description?.replace('productos', query);
      }
    }

    if (path === '/amadrid') {
      if (store) {
        title = title?.replace('en Madrid', `en ${store}`);
        description = description?.replace('en las tiendas de España', `en ${store}`);
      }

      if (query) {
        title = title?.replace('productos', query);
        description = description?.replace('productos', query);
      }
    }

    if (title) {
      document.title = title;
    }

    if (description && descriptionTag) {
      descriptionTag.setAttribute('content', description);
    }

    if (keywordsTag) {
      let keywordsContent = keywordsTag.getAttribute('content') ?? '';

      if (store) {
        keywordsContent += `, ${store}, comprar en ${store}, ${store} tienda, ${store} tienda online`;
      }

      if (query) {
        keywordsContent += `, comprar ${query}, comprar ${query} online`;
      }

      if (query && store) {
        keywordsContent += `, comprar ${query} en ${store}`;
      }

      keywordsTag.setAttribute('content', keywordsContent);
    }
  };

  return {
    setSeo
  };
};
